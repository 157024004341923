<template>
  <ul class="logo-list">
    <li class="logo-item">
      <a
        href="https://github.com/Paracetamol56"
        name="GitHub"
        target="_blank"
        class="logo-link"
      >
        <img
          class="logo-image"
          src="https://cdn.simpleicons.org/github/f5f3fa"
          alt="GitHub"
        />
      </a>
    </li>
    <li class="logo-item">
      <a
        href="https://www.linkedin.com/in/matheogaluba/"
        name="Linkedin"
        target="_blank"
        class="logo-link"
      >
        <img
          class="logo-image"
          src="https://cdn.simpleicons.org/linkedin/f5f3fa"
          alt="Linkedin"
        />
      </a>
    </li>
    <li class="logo-item">
      <a
        href="mailto:matheo.galu56@gmail.com"
        name="Mail"
        target="_blank"
        class="logo-link"
      >
        <img
          class="logo-image"
          src="https://cdn.simpleicons.org/gmail/f5f3fa"
          alt="Mail"
        />
      </a>
    </li>
    <li class="logo-item">
      <a
        href="https://paracetamol56.itch.io"
        name="Itch.io"
        target="_blank"
        class="logo-link"
      >
        <img
          class="logo-image"
          src="https://cdn.simpleicons.org/itchdotio/f5f3fa"
          alt="Itch.io"
        />
      </a>
    </li>
    <li class="logo-item">
      <a
        href="https://www.printables.com/@MatheoGaluba_1537385"
        name="Printables"
        target="_blank"
        class="logo-link"
      >
        <img
          class="logo-image"
          src="https://cdn.simpleicons.org/printables/f5f3fa"
          alt="Printables"
        />
      </a>
    </li>
    <li class="logo-item">
      <a
        href="https://t.me/paracetamol_56"
        name="Telegram"
        target="_blank"
        class="logo-link"
      >
        <img
          class="logo-image"
          src="https://cdn.simpleicons.org/telegram/f5f3fa"
          alt="Telegram"
        />
      </a>
    </li>
    <li class="logo-item">
      <a
        href="https://discordapp.com/users/609748578004500517/"
        name="Discord"
        target="_blank"
        class="logo-link"
      >
        <img
          class="logo-image"
          src="https://cdn.simpleicons.org/discord/f5f3fa"
          alt="Discord"
        />
      </a>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.logo-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 40%;
  margin: 0 auto 20px;
  padding: 0;
  list-style: none;
  .logo-item {
    margin: 0 10px;
    .logo-link {
      .logo-image {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
</style>
